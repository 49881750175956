import Vue from "vue"
import DatePicker from 'vue2-datepicker'
import Loading from '@/components/widgets/Loading.vue'
import SortedTablePlugin from "vue-sorted-table"
import CreateAccount from './widgets/widget-account-create'
import Paginate from "@/components/widgets/pagination.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})
export default {
  components: {
    Loading,
    DatePicker,
    CreateAccount,
    Paginate
  },
  data: function() {
    return {
      role: "",
      branch: "",
      keyword_document_no: "",
      keyword_status: null,
      keyword_start: null,
      keyword_end: null,
      busy: false,
      apiStatus: 'ไม่พบข้อมูล',
      totalItem: 0,
      loading: false, 
      state: false,
      create: {
        mode: 'create',
        Id: null,
        opened: false,
      },
      getPaid: {
        Id: null,
        state: false
      },
      preview: {
        uuid: "",
        data: [],
        state: false,
        status: 0,
        role: "",
        branch: "",
      },
      status: null,
      doc: {
        start: null,
        end: null
      },
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      perPage: 8,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      items:[],
      fields: [
        { 
          key: 'account_number', 
          label: 'เลขที่บัญชี',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'account_name', 
          label: 'ชื่อบัญชี',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'account_type', 
          label: 'ประเภทบัญชี',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'account_bank', 
          label: 'ธนาคาร',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'note', 
          label: 'หมายเหตุ',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'is_primary', 
          label: 'เป็นบัญชีหลัก',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'action', 
          label: '#', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right' 
        }
      ],
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    async getRole(){
      const res = await this.$store.dispatch('Auth/getRole', {}, { root: true })
      this.role = res.data.role
      this.branch = res.data.branch_id
    },
    handleCreateEvent(event) {
      if (event.status) {
        this.create.Id = null
        this.create.opened = false
        setTimeout(() => {
          const currentPage = this.currentPage
          this.onInitData(currentPage)
        }, 500)
      }
    },
    oncreate() {
      this.create.mode = 'create'
      this.create.opened = !this.create.opened
    },
    onupdate(id) {
      this.create.Id = id
      this.create.mode = 'update'
      this.create.opened = !this.create.opened
    },
    ondeleted(id) {
      this.$swal({
        width: "29rem",
        padding: '1.825em',
        html: '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
          '<div class="d-flex flex-column">'+
          '<span class="font-bold text-base py-2.5 text-left">คุณต้องการจะลบข้อมูลนี้หรือไม่?</span>'+
          '<span class="text-base text-left text-gray-500">หากคุณยืนยันแล้ว ข้อมูลจะไม่สามารถกู้คืนกลับมาได้?</span>'+
          '</div>'+
        '</div>',
          allowOutsideClick: false,
          focusConfirm: false,
          customClass: {
            popup: 'swal2-custom-rounded',
            closeButton: 'text-3xl swal2-custom-close',
            actions: 'justify-content-end',
            contant: 'd-flex flex-row justify-content-around px-2',
            title: 'd-none',
            confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
            cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
          },
          cancelButtonColor: '#dc4a38',
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: "ตกลง",
          showCancelButton: true,
          showCloseButton: true,
      }).then(async confirm => {
        if(confirm.value){
          this.loading = true
          this.realDelete(id)
        }
      })
    },
    async realDelete(id) {
      try {
        await Vue.prototype.$axios.delete(`${Vue.prototype.$host}/api/v1/document/quotation/branch/bank_account/${id}`)

        this.loading = false
        this.$swal({
          width: "29rem",
          padding: '1.825em',
          html: '<div class="d-flex flex-row align-items-center">' +
            '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
            '<div class="d-flex flex-column">'+
            '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
            '<span class="text-base text-left text-gray-500">ลบข้อมูลเรียบร้อยแล้ว</span>'+
            '</div>'+
          '</div>',
            allowOutsideClick: false,
            focusConfirm: false,
            customClass: {
              popup: 'swal2-custom-rounded',
              closeButton: 'text-3xl swal2-custom-close',
              actions: 'justify-content-end',
              contant: 'd-flex flex-row justify-content-around px-2',
              title: 'd-none',
              confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
              cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
            },
            cancelButtonColor: '#dc4a38',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: "ตกลง",
            showCancelButton: false,
            showCloseButton: true,
        }).then(async confirm => {
          if(confirm.value){
            this.onInitData(this.currentPage)
          }
        })
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }      
    },
    async onInitData(currentPage) {
      this.currentPage = currentPage
      this.busy = true
      this.apiStatus = 'กำลังค้นหาข้อมูล..'
      // this.items = []
      try {
        const page = currentPage ? currentPage : 1
        const result = await Vue.prototype.$axios.get(`${Vue.prototype.$host}/api/v1/document/quotation/branch/bank_accounts?page=${page}`)
        this.busy = false

        this.apiStatus = 'ไม่พบข้อมูล'
        this.currentData = result.data;
        this.items = result.data.data
        this.totalItem = result.data.total
      } catch (error) {
        this.apiStatus = 'ไม่พบข้อมูล'
        this.onExceptionHandler(error.response.data.message);
        this.busy = false
        return []
      }
    },
  },
  mounted() {
    this.onInitData(1)
    this.getRole()
  }
}
