import Drawer from '@/components/drawers'
import { validationMixin } from "vuelidate"
import { required, requiredIf, minLength } from "vuelidate/lib/validators"
import Loading from '@/components/widgets/Loading.vue'
import Vue from 'vue'

export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'create'
    },
    id: {
      type: String
    }
  },
  components: {
    Loading,
    Drawer
  },
  mixins: [validationMixin],
  validations: {
    form: {
      account_number: { required },
      account_name: { required },
      account_type: { required },
      account_bank: { required },
      note: {  },
      is_primary: {  },
    },
  },
  data() {
    return {
      role: "",
      branch: "",
      branches: [],
      loading: false,
      drawerOpened: false,
      preview: null,
      showFileInput: true,
      form: {
        _id: null,
        account_number: "",
        account_name: "",
        account_type: null,
        account_bank: null,
        note: "",
        is_primary: false,
      },
      types: [
        { text: 'เงินสด', value: 0 },
        { text: 'เครดิต', value: 1 }
      ]
    }
  },
  watch: {
    'opened'(n, o) {
      if (n) {
        this.showFileInput = true
        this.drawerOpened = n
        if(this.mode === 'update') {
          this.onInitData()
        }
      }
    }
  },
  methods: {
    async getRole(){
      const res = await this.$store.dispatch('Auth/getRole', {}, { root: true })
      this.role = res.data.role
      this.branch = res.data.branch_id
      this.form.branch_id = (res.data.branch_id !== "0" && res.data.branch_id) ? res.data.branch_id : null
    },
    resetForm () {
      this.form._id = null
      this.form.account_number = null
      this.form.account_name = null
      this.form.account_type = null
      this.form.account_bank = null
      this.form.note = null
      this.form.is_primary = null
      this.$nextTick(() => {
        this.$v.form.$reset()
      })      
    },
    formBuilder(obj) {
      this.form._id = obj.data?.uuid ?? ''
      this.form.account_number = obj.data?.account_number ?? ''
      this.form.account_name = obj.data?.account_name ?? ''
      this.form.account_type = obj.data?.account_type ?? ''
      this.form.account_bank = obj.data?.account_bank ?? ''
      this.form.note = obj.data?.note ?? ''
      this.form.is_primary = obj.data?.is_primary === 1 ? true : false
      this.$v.form.$touch()
    },
    onCloseDrawer(event) {
      this.drawerOpened = false
      this.resetForm()
      this.$emit('close-event', { 
        status: true
      })
    },
    async onInitData() {
      try {
        if (this.id) {
          this.loading = true
          const result = await Vue.prototype.$axios.get(`${Vue.prototype.$host}/api/v1/document/quotation/branch/bank_account/${this.id}`)
          setTimeout(() => {
            this.loading = false
            if (result.status === 200) {
              this.formBuilder(result)
            }
          }, 500)
        }
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }
    },
    onSubmitForm() {
      try {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }
        this.loading = true
        const formData = new FormData()
        formData.append("account_number", this.form.account_number)
        formData.append("account_name", this.form.account_name)
        formData.append("account_type", this.form.account_type)
        formData.append("account_bank", this.form.account_bank)
        formData.append("note", this.form.note ?? '')
        formData.append("is_primary", this.form.is_primary ? true : false)

        setTimeout(async() => {
          let result = {}
          if (this.mode === 'create') {
            result = await Vue.prototype.$axios.post(`${Vue.prototype.$host}/api/v1/document/quotation/branch/bank_account`, formData)
          } else {
            result = await Vue.prototype.$axios.post(`${Vue.prototype.$host}/api/v1/document/quotation/branch/bank_account/${this.id}`, formData)
          }

          if (result.status === 201 || result.status === 200) {
            this.resetForm()
            this.$swal({
              width: "29rem",
              padding: '1.825em',
              html: '<div class="d-flex flex-row align-items-center">' +
                '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
                '<div class="d-flex flex-column">'+
                '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
                `<span class="text-base text-left text-gray-500">${result.data?.message ?? "บันทึกข้อมูลเรียบร้อยแล้ว"}</span>`+
                '</div>'+
              '</div>',
                allowOutsideClick: false,
                focusConfirm: false,
                customClass: {
                  popup: 'swal2-custom-rounded',
                  closeButton: 'text-3xl swal2-custom-close',
                  actions: 'justify-content-end',
                  contant: 'd-flex flex-row justify-content-around px-2',
                  title: 'd-none',
                  confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                  cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
                },
                showCancelButton: false,
                confirmButtonText: "ตกลง",
                cancelButtonText: "ยกเลิก",
                showCloseButton: true,
            }).then(result => {
              this.onCloseDrawer()
            })
            this.loading = false

          } else {
            this.loading = false
            this.onExceptionHandler(result.data.message)
          }
        }, 500)
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }
    }
  },
  created(){
    this.getRole()
  }
}