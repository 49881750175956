export default {
  name: 'Drawer',
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: "drawer-header-0"
    }
  },
  data() {
    return {
      InnerClass: ''
    }
  },
  watch: {
    'opened' (n, o) {
      if (!n) {
        const drawer = this.$refs.drawer
        const mark = this.$refs.drawer_mask
        if (drawer) {
            drawer.classList.add('draweropened-in');
            mark.classList.add('invisible')
            this.$emit('openedChanged', !n)
        }
      } else {
        const drawer = this.$refs.drawer
        const mark = this.$refs.drawer_mask
        mark.classList.remove('invisible')
        drawer.classList.remove('draweropened-in')
      }
    },
    customClass: {
      // the callback will be called immediately after the start of the observation
      immediate: true, 
      handler (val, oldVal) {
        if (val) {
          this.InnerClass = val
        }
      }
    }
  }
}
